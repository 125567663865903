<template>
  <!-- Top navigation -->
  <DetailTopNav :heading="this.viewType" :subheading="this.assetType" />

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'floorplans' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'panoramas' || viewType == 'virtual-tour' && this.assetTypesReady === true">
    <iframe :src="this.assetTypes[this.order].url" :title="this.assetTypes[this.order].title" width="100%" height="100%"></iframe>
  </div>

  <OffCanvas />
</template>

<script>
import DetailTopNav from '@/components/DetailTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    DetailTopNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null,
      assetNames: null,
      assetUrls: null,
      componentName: "AssetView",
      assetTypesReady: false
    }
  },
  created() {
    if (this.assetType === 'type-a') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.typeAAsset.videos
        this.assetNames = this.theme.typeAAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.typeAAsset.floorplans
        this.assetNames = this.theme.typeAAsset.floorplansName
        this.assetUrls = this.theme.typeAAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.typeAAsset.panoramas
        this.assetNames = this.theme.typeAAsset.panoramasName
        this.assetUrls = this.theme.typeAAsset.panoramasUrl
      }
    } else if (this.assetType === 'type-b') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.typeBAsset.videos
        this.assetNames = this.theme.typeBAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.typeBAsset.floorplans
        this.assetNames = this.theme.typeBAsset.floorplansName
        this.assetUrls = this.theme.typeBAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.typeBAsset.panoramas
        this.assetNames = this.theme.typeBAsset.panoramasName
        this.assetUrls = this.theme.typeBAsset.panoramasUrl
      }
    } else if (this.assetType === 'type-c') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.typeCAsset.videos
        this.assetNames = this.theme.typeCAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.typeCAsset.floorplans
        this.assetNames = this.theme.typeCAsset.floorplansName
        this.assetUrls = this.theme.typeCAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.typeCAsset.panoramas
        this.assetNames = this.theme.typeCAsset.panoramasName
        this.assetUrls = this.theme.typeCAsset.panoramasUrl
      }
    } else if (this.assetType === 'rooftop-amenities') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.amenitiesAsset.videos
        this.assetNames = this.theme.amenitiesAsset.videosName
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.amenitiesAsset.panoramas
        this.assetNames = this.theme.amenitiesAsset.panoramasName
        this.assetUrls = this.theme.amenitiesAsset.panoramasUrl
      }
    }

    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array

      console.log(response.data);
      
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName,
          title: this.assetNames[j],
          url: this.assetUrls[j],
          id: j++
        };
      });

      // Custom order array based on section and type
      if( this.assetOrder ) {
        this.assetTypes = this.reorder(menuItems, this.assetOrder);
      } else {
        this.assetTypes = menuItems
      }

      this.assetTypesReady = true
    })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>