import { reactive } from "vue";

const theme = reactive({
  agentLogo: "colliers-footer-logo.svg",
  devLogo: "florian-header-logo.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "b4276253-2728-4db8-90a0-62a463e94792",
  videoLibraryId: "64356",
  streamCdnUrl: "https://vz-a20f17b9-3ee.b-cdn.net",
  streamApiKey: "df7850a6-a44f-4301-9ef29f1ed4f6-38a7-41d8",
  storageApiPassword: "a8785448-3dc7-406d-a55c777a09af-4765-484d",
  cdnUrl: "https://florianweb.metaluxe.com.au",
  devFolder: "/florianweb",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  
  // top level menu
  assetsMenuOrder: [
    'type-a',
    'type-b',
    'type-c',
    'rooftop-amenities'
  ],
  
  typeAMenuOrder: [
    'videos',
    'floorplans'
  ],
  
  typeBMenuOrder: [
    'videos',
    'floorplans'
  ],
  
  typeCMenuOrder: [
    'videos',
    'floorplans'
  ],

  amenitiesMenuOrder: [
    'videos'
  ],
  
  typeAAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    floorplans: [ 0 ],
    floorplansName: [
      'Type A Floorplan'
    ],
    floorplansUrl: [
      ''
    ]
  },
  
  typeBAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Cinematic',
      'Walkthrough'
    ],
    floorplans: [ 0 ],
    floorplansName: [
      'Type B Floorplan'
    ],
    floorplansUrl: [
      ''
    ]
  },
  
  typeCAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    floorplans: [ 0 ],
    floorplansName: [
      'Type C Floorplan'
    ],
    floorplansUrl: [
      ''
    ]
  },
  
  // amenities asset order
  amenitiesAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ]
  },
});

export default { theme };